var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-app":""}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h6',{staticClass:"my-0 card-title"},[_vm._v("Nombre: "+_vm._s(_vm.producto.name)+" | Bodega ID: "+_vm._s(_vm.warehouse_id))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"badge badge-dark badge-pill float-right sdw"},on),[_c('i',{staticClass:"fas fa-box text-white"})])]}}])},[_c('span',[_vm._v("Detalle Producto "+_vm._s(_vm.getProductBySku.sku)+" en Bodega "+_vm._s(_vm.warehouse_id))])])],1),_c('div',{staticClass:"card-body"},[_c('ul',{staticClass:"list-group"},[_c('li',{staticClass:"list-group-item"},[_c('strong',[_vm._v("Nombre:")]),_vm._v(" "+_vm._s(_vm.producto.name)+" "),_vm._m(0)]),_c('li',{staticClass:"list-group-item list-group-item-dark"},[_c('strong',[_vm._v("Sku:")]),_vm._v(" "+_vm._s(_vm.producto.sku)+" "),_vm._m(1)]),_c('li',{staticClass:"list-group-item"},[_c('strong',[_vm._v("Stock:")]),_vm._v(" "+_vm._s(_vm.producto.sum_quantity)+" "),_vm._m(2)]),_c('li',{staticClass:"list-group-item"},[_c('strong',[_vm._v("Stock Físico:")]),_vm._v(" "+_vm._s(_vm.producto.sum_physical_quantity)+" "),_vm._m(3)]),_c('li',{staticClass:"list-group-item"},[_c('strong',[_vm._v("Stock Reservado:")]),_vm._v(" "+_vm._s(_vm.producto.sum_reserved_quantity)+" "),_vm._m(4)])])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card mb-2"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"d-flex justify-content-between align-items-center mb-3"},[_c('span',{staticClass:"text-muted"},[_vm._v("Actualizar Stock")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"badge badge-secondary badge-pill"},on),[_c('i',{staticClass:"fas fa-dolly-flatbed text-black"})])]}}])},[_c('span',[_vm._v("Actualizar Producto en Bodega")])])],1),_c('v-form',{ref:"form",staticClass:"px-3",attrs:{"lazy-validation":""}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"prepend-icon":"compare_arrows","label":"Cantidad","type":"number","outlined":""},model:{value:(_vm.quantity_update),callback:function ($$v) {_vm.quantity_update=$$v},expression:"quantity_update"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-select',{attrs:{"items":_vm.current_warehouse,"prepend-icon":"north_east","item-text":"name","item-value":"id","label":"Bodega TO"},model:{value:(_vm.warehouse_to_update),callback:function ($$v) {_vm.warehouse_to_update=$$v},expression:"warehouse_to_update"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-btn',{staticClass:"my-3 btn-block",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.actualizarStock('Operación realizada exitosamente!')}}},[_vm._v("Guardar")])],1)],1)],1)],1)],1)]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"d-flex justify-content-between align-items-center mb-3"},[_c('span',{staticClass:"text-muted"},[_vm._v("Mover Productos entre Bodegas")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"badge badge-secondary badge-pill"},on),[_c('i',{staticClass:"fas fa-shipping-fast text-black"})])]}}])},[_c('span',[_vm._v("Mover Producto entre Bodegas")])])],1),_c('v-form',{ref:"form",staticClass:"px-3",attrs:{"lazy-validation":""}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","offset-md":"8"}},[_c('v-btn',{staticClass:"my-3 mr-5 btn-block",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.moveStock('Operación realizada exitosamente!')}}},[_vm._v("Guardar")])],1)],1),_c('v-row',[(_vm.warehouse.length > 0)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-select',{attrs:{"items":_vm.current_warehouse,"prepend-icon":"home","item-text":"name","item-value":"id","label":"Bodega FROM"},model:{value:(_vm.warehouse_from),callback:function ($$v) {_vm.warehouse_from=$$v},expression:"warehouse_from"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"prepend-icon":"compare_arrows","label":"Cantidad","type":"number","rules":[ function (v) { return v <= _vm.producto.sum_quantity || 'No se puede transferir mayor a la cantidad en la bodega'; } ],"outlined":""},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=$$v},expression:"quantity"}})],1),(_vm.warehouses.length > 0)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-select',{attrs:{"items":_vm.no_cu_warehouses,"prepend-icon":"north_east","item-text":"name","item-value":"id","label":"Bodega TO"},model:{value:(_vm.warehouse_to),callback:function ($$v) {_vm.warehouse_to=$$v},expression:"warehouse_to"}})],1):_vm._e()],1)],1)],1)],1)])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header bg-secondary"},[_vm._v(" Movimientos "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"badge badge-success badge-pill float-right sdw"},on),[_c('i',{staticClass:"fas fa-exchange-alt text-white"})])]}}])},[_c('span',[_vm._v("Movimientos en Bodega")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"badge badge-secondary badge-pill float-right sdw pointer mr-3",on:{"click":function($event){return _vm.reinitializeMovements()}}},on),[_c('i',{staticClass:"fas fa-sync text-success"})])]}}])},[_c('span',[_vm._v("Actualizar Tabla")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"badge badge-success badge-pill pointer float-right sdw mr-3",on:{"click":_vm.exportMovementsExcel}},on),[_c('i',{staticClass:"fas fa-file-excel text-white"})])]}}])},[_c('span',[_vm._v("Exportar Bodegas a Excel")])])],1),_c('div',{staticClass:"card-body"},[_c('v-data-table',{staticClass:"elevation-1 datatable",attrs:{"headers":_vm.headersMovimientos,"items":_vm.getMovementsByProductWarehouse,"search":_vm.search,"sort-by":"updated_at","sort-desc":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('div',{staticClass:"kt-input-icon kt-input-icon--left"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control form-control-md col-md-8",attrs:{"append-icon":"search","label":"Búsqueda","type":"text","placeholder":"Buscar..."},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}}),_c('span',{staticClass:"kt-input-icon__icon"},[_c('span',[_c('i',{staticClass:"la la-search"})])])]),_c('v-spacer')],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"#87CEFA","loading":_vm.loadingMovements},on:{"click":function($event){return _vm.reinitializeMovements()}}},[_vm._v("Cargando ..")])]},proxy:true},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [(parseInt(item.sign) > 0 && parseInt(item.quantity) != 0)?_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(Math.abs(parseInt(item.quantity)))+" "),_c('v-icon',{attrs:{"color":"#00ff00"}},[_vm._v("mdi-arrow-up-bold")])],1):_vm._e(),(parseInt(item.sign) < 0)?_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(Math.abs(parseInt(item.quantity)))+" "),_c('v-icon',{attrs:{"color":"#ff0000"}},[_vm._v("mdi-arrow-down-bold")])],1):_vm._e(),(parseInt(item.sign) > 0 && parseInt(item.quantity) == 0)?_c('td',{staticClass:"text-right"},[_c('v-icon',{attrs:{"color":"#0000ff"}},[_vm._v("mdi-arrow-down-equal")])],1):_vm._e()]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$moment(item.updated_at).format('LLLL'))+" ")])]}}])})],1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"badge badge-dark badge-pill float-right"},[_c('i',{staticClass:"fas fa-box"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"badge badge-secondary badge-pill float-right"},[_c('i',{staticClass:"fas fa-cubes"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"badge badge-dark badge-pill float-right"},[_c('i',{staticClass:"fas fa-box"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"badge badge-dark badge-pill float-right"},[_c('i',{staticClass:"fas fa-box"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"badge badge-dark badge-pill float-right"},[_c('i',{staticClass:"fas fa-box"})])}]

export { render, staticRenderFns }