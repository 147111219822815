<template>
  <div data-app>
    <div class="container-fluid">
      <!-- 1°first ROW -->
      <div class="row">
        <div class="col-md-12">
          <!-- 1°first Card -->
          <div class="card">
            <div class="card-header">
              <h6 class="my-0 card-title">Nombre: {{producto.name}} | Bodega ID: {{warehouse_id}}</h6>
              <v-tooltip bottom>
                <template v-slot:activator="{ on}">
                  <span v-on="on" class="badge badge-dark badge-pill float-right sdw">
                    <i class="fas fa-box text-white"></i>
                  </span>
                </template>
                <span>Detalle Producto {{getProductBySku.sku}} en Bodega {{warehouse_id}}</span>
              </v-tooltip>
            </div>
            <div class="card-body">
              <!-- 1°first List-->
              <ul class="list-group">
                <li class="list-group-item">
                  <strong>Nombre:</strong>
                  {{producto.name}}
                  <span
                    class="badge badge-dark badge-pill float-right"
                  >
                    <i class="fas fa-box"></i>
                  </span>
                </li>
                <li class="list-group-item list-group-item-dark">
                  <strong>Sku:</strong>
                  {{producto.sku}}
                  <span
                    class="badge badge-secondary badge-pill float-right"
                  >
                    <i class="fas fa-cubes"></i>
                  </span>
                </li>
                <li class="list-group-item">
                  <strong>Stock:</strong>
                  {{producto.sum_quantity}}
                  <span
                    class="badge badge-dark badge-pill float-right"
                  >
                    <i class="fas fa-box"></i>
                  </span>
                </li>
                <li class="list-group-item">
                  <strong>Stock Físico:</strong>
                  {{producto.sum_physical_quantity}}
                  <span
                    class="badge badge-dark badge-pill float-right"
                  >
                    <i class="fas fa-box"></i>
                  </span>
                </li>
                <li class="list-group-item">
                  <strong>Stock Reservado:</strong>
                  {{producto.sum_reserved_quantity}}
                  <span
                    class="badge badge-dark badge-pill float-right"
                  >
                    <i class="fas fa-box"></i>
                  </span>
                </li>
              </ul>
            </div>
            <div class="card-body">
              <!-- 2°second Card -->
              <div class="card mb-2">
                <div class="card-body">
                  <h4 class="d-flex justify-content-between align-items-center mb-3">
                    <span class="text-muted">Actualizar Stock</span>
                    <v-tooltip top>
                      <template v-slot:activator="{ on}">
                        <span v-on="on" class="badge badge-secondary badge-pill">
                          <i class="fas fa-dolly-flatbed text-black"></i>
                        </span>
                      </template>
                      <span>Actualizar Producto en Bodega</span>
                    </v-tooltip>
                  </h4>
                  <v-form class="px-3" ref="form" lazy-validation>
                    <v-container>
                      <!-- 2°second ROW -->
                      <v-row>
                        <v-col cols="12" sm="4" md="4">
                          <v-text-field
                            v-model="quantity_update"
                            prepend-icon="compare_arrows"
                            label="Cantidad"
                            type="number"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-select
                            v-model="warehouse_to_update"
                            :items="current_warehouse"
                            prepend-icon="north_east"
                            item-text="name"
                            item-value="id"
                            label="Bodega TO"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-btn
                            color="primary"
                            dark
                            class="my-3 btn-block"
                            @click="actualizarStock('Operación realizada exitosamente!')"
                          >Guardar</v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </div>
              </div>
              <!-- 3°third Card -->
              <div class="card">
                <div class="card-body">
                  <h4 class="d-flex justify-content-between align-items-center mb-3">
                    <span class="text-muted">Mover Productos entre Bodegas</span>
                    <v-tooltip top>
                      <template v-slot:activator="{ on}">
                        <span v-on="on" class="badge badge-secondary badge-pill">
                          <i class="fas fa-shipping-fast text-black"></i>
                        </span>
                      </template>
                      <span>Mover Producto entre Bodegas</span>
                    </v-tooltip>
                  </h4>
                  <v-form class="px-3" ref="form" lazy-validation>
                    <v-container>
                      <!-- 3°third ROW -->
                      <v-row>
                        <v-col cols="12" sm="6" md="4" offset-md="8">
                          <v-btn
                            color="primary"
                            dark
                            class="my-3 mr-5 btn-block"
                            @click="moveStock('Operación realizada exitosamente!')"
                          >Guardar</v-btn>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" md="4" v-if="warehouse.length > 0">
                          <v-select
                            v-model="warehouse_from"
                            :items="current_warehouse"
                            prepend-icon="home"
                            item-text="name"
                            item-value="id"
                            label="Bodega FROM"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-text-field
                            v-model="quantity"
                            prepend-icon="compare_arrows"
                            label="Cantidad"
                            type="number"
                            :rules="[ v => v <= producto.sum_quantity || 'No se puede transferir mayor a la cantidad en la bodega' ] "
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" v-if="warehouses.length > 0">
                          <v-select
                            v-model="warehouse_to"
                            :items="no_cu_warehouses"
                            prepend-icon="north_east"
                            item-text="name"
                            item-value="id"
                            label="Bodega TO"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 4°fourth ROW -->
      <div class="row">
        <div class="col-md-12">
          <!-- 4°fourth Card -->
          <div class="card">
            <div class="card-header bg-secondary">
              Movimientos
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="badge badge-success badge-pill float-right sdw">
                    <i class="fas fa-exchange-alt text-white"></i>
                  </span>
                </template>
                <span>Movimientos en Bodega</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on}">
                  <span
                    v-on="on"
                    class="badge badge-secondary badge-pill float-right sdw pointer mr-3"
                    @click="reinitializeMovements()"
                  >
                    <i class="fas fa-sync text-success"></i>
                  </span>
                </template>
                <span>Actualizar Tabla</span>
              </v-tooltip>
              <v-tooltip top>
              <template v-slot:activator="{ on }">
                <span
                  v-on="on"
                  @click="exportMovementsExcel"
                  class="badge badge-success badge-pill pointer float-right sdw mr-3"
                >
                  <i class="fas fa-file-excel text-white"></i>
                </span>
              </template>
              <span>Exportar Bodegas a Excel</span>
            </v-tooltip>
            </div>
            <div class="card-body">
              <v-data-table
                :headers="headersMovimientos"
                :items="getMovementsByProductWarehouse"
                :search="search"
                sort-by="updated_at"
                :sort-desc="true"
                class="elevation-1 datatable"
              >
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <div class="kt-input-icon kt-input-icon--left">
                      <input
                        v-model="search"
                        append-icon="search"
                        label="Búsqueda"
                        type="text"
                        class="form-control form-control-md col-md-8"
                        placeholder="Buscar..."
                      />
                      <span class="kt-input-icon__icon">
                        <span>
                          <i class="la la-search"></i>
                        </span>
                      </span>
                    </div>
                    <!-- <v-text-field
                      class="text-xs-center"
                      v-model="search"
                      append-icon="search"
                      label="Búsqueda"
                    ></v-text-field>
                    <v-divider class="mx-4" inset vertical></v-divider>-->
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
                <template v-slot:no-data>
                  <v-btn
                    color="#87CEFA"
                    :loading="loadingMovements"
                    @click="reinitializeMovements()"
                  >Cargando ..</v-btn>
                </template>
                <template v-slot:item.quantity="{ item }">
                  <td class="text-right" v-if="parseInt(item.sign) > 0 && parseInt(item.quantity) != 0">
                    {{Math.abs(parseInt(item.quantity))}} <v-icon color="#00ff00">mdi-arrow-up-bold</v-icon>
                  </td>
                  <td class="text-right" v-if="parseInt(item.sign) < 0">
                    {{Math.abs(parseInt(item.quantity))}} <v-icon color="#ff0000">mdi-arrow-down-bold</v-icon>
                  </td>
                  <td class="text-right" v-if="parseInt(item.sign) > 0 && parseInt(item.quantity) == 0">
                    <v-icon color="#0000ff">mdi-arrow-down-equal</v-icon>
                  </td>
                </template>
                <template v-slot:item.updated_at="{ item }">
                  <td class="text-left">
                    {{$moment(item.updated_at).format('LLLL')}}
                  </td>
                </template>
              </v-data-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  props: ["warehouse_id", "sku"],
  data: () => ({
    hover: false,
    loadingMovements: false,
    producto: {
      name: "",
      sku: "",
      sum_quantity: "",
      sum_physical_quantity: "",
      sum_reserved_quantity: "",
    },
    current_warehouse: [],
    warehouse_to_update: [],
    no_cu_warehouses: [],
    warehouse_from: [],
    warehouse_to: 0,
    warehouses: [],
    warehouse: [],
    reason: 0,
    reasons: [],
    getProductBySku: {},
    getMovementsByProductWarehouse: [],
    getStockBySku: {},
    getMovementsBySku: {},
    dialog: false,
    readonly: true,
    disabled: false,
    boolean: false,
    search: "",
    quantity: 0,
    quantity_update: 0,
    headersMovimientos: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Fecha", value: "updated_at" },
      { text: "Stock", value: "quantity" },
      { text: "Razón", value: "reason.name" },
      { text: "Bodega", value: "warehouse.name" },
      { text: "Balance", value: "stock_balance" },
    ],
    movimientos: [],
  }),
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "➀ Lista de Productos", route: "/inventario/stock/productos" },
      {
        title: "➁ Detalle Producto",
        route: `/inventario/stock/productos/${this.sku}`,
      },
      {
        title: "➂ Detalle Producto en Bodega",
        route: `/inventario/stock/productos/${this.sku}/${this.warehouse_id}`,
      },
    ]);
    this.loadingMovements = true;
    this.onResize();
    // this.fetchReasons();
    this.fetchProduct();
    this.fetchProductMovements();
    this.fetchWarehouses();

    this.fetchCurrentWarehouse();
  },
  created() {},
  methods: {
    initialize() {},
    moveStock(mensaje) {
      var vm = this;
      //console.log(vm.warehouse_to);

      this.axios({
        url: "inventory/stock/sku/warehouse",
        method: "POST",
        data: {
          sku: vm.sku,
          quantity: vm.quantity,
          warehouse_from: vm.warehouse_from,
          warehouse_to: vm.warehouse_to,
        },
      })
        .then((response) => {
          vm.fetchProduct();
          vm.fetchProductMovements();

          vm.$bvToast.toast(mensaje, {
            title: `Información`,
            variant: "success",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    actualizarStock(mensaje) {
      var vm = this;
      this.axios({
        url: "inventory/stock/products",
        method: "POST",
        data: {
          sku: vm.sku,
          quantity: Number(vm.quantity_update),
          warehouse_id: vm.warehouse_to_update,
        },
      })
        .then((response) => {
          console.log(response);
          vm.fetchProduct();
          vm.fetchProductMovements();

          vm.$bvToast.toast(mensaje, {
            title: `Información`,
            variant: "success",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    getColor(stock) {
      if (stock >= 3) return "#32CD32";
      else if (stock >= 2) return "#D2691E";
      else return "#B22222";
    },
    fetchProduct() {
      var vm = this;
      vm.producto.sum_quantity = 0;
      vm.producto.sum_physical_quantity = 0;
      vm.producto.sum_reserved_quantity = 0;

      this.axios({
        url:
          "inventory/stock/products/" +
          vm.sku +
          "/warehouses/" +
          vm.warehouse_id,
        method: "GET",
      })
        .then((response) => {
          console.log(response.data.data[0].product.name);
          vm.producto.name = response.data.data[0].product.name;
          vm.producto.sku = response.data.data[0].product.sku;
          vm.warehouse.unshift(response.data.data[0].warehouse);

          response.data.data.map(function (value, key) {
            vm.producto.sum_quantity =
              Number(vm.producto.sum_quantity) + Number(value.quantity);

            vm.producto.sum_physical_quantity =
              Number(vm.producto.sum_physical_quantity) +
              Number(value.physical_quantity);

            vm.producto.sum_reserved_quantity =
              Number(vm.producto.sum_reserved_quantity) +
              Number(value.reserved_quantity);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchProductMovements() {
      var vm = this;

      this.axios({
        url:
          "inventory/stock/products/movements/" +
          vm.sku +
          "/warehouses/" +
          vm.warehouse_id,
        method: "GET",
      })
        .then((response) => {
          console.log(response);
          this.loadingMovements = false;
          vm.getMovementsByProductWarehouse = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchWarehouses() {
      var vm = this;
      this.axios({
        url: "inventory/warehouse/by_store",
        method: "GET",
      })
        .then((response) => {
          console.log(response.data.data);
          vm.warehouses = response.data.data;
          vm.selecCurrentWarehouse();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selecCurrentWarehouse() {
      var vm = this;
      var no_current_warehouse = [];
      var yep_current_warehouse = [];

      vm.warehouses.forEach((element) => {
        if (element.id == vm.warehouse_id) {
          yep_current_warehouse.push(element);
        } else {
          no_current_warehouse.push(element);
        }
      });
      vm.no_cu_warehouses = no_current_warehouse;
      vm.warehouse_from = yep_current_warehouse;
      vm.warehouse_to_update = yep_current_warehouse;
    },
    fetchReasons() {
      var vm = this;
      this.axios({
        url: "inventory/reasons",
        method: "GET",
      })
        .then((response) => {
          vm.reasons = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchCurrentWarehouse() {
      var vm = this;
      var cu_wh = [];
      this.axios({
        url: "/inventory/warehouse/" + vm.warehouse_id,
        method: "GET",
      })
        .then((response) => {
          // console.log(response.data);
          cu_wh.push(response.data);
          // console.log(cu_wh);
          vm.current_warehouse = cu_wh;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    reinitializeMovements() {
      this.getMovementsByProductWarehouse = [];
      this.loadingMovements = true;
      this.fetchProductMovements();
    },
    exportMovementsExcel() {
      var vm = this;
      let headers = vm.headersMovimientos.map((e) => e.text);
      let datos = vm.getMovementsByProductWarehouse;
      this.axios({
        url: "excel/from_array",
        method: "POST",
        data: {
          data: datos,
          cabeceras: headers,
        },
        responseType: "blob",
      })
        .then((response) => {
          const newBlob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          });
          const data = window.URL.createObjectURL(newBlob);
          window.open(data, "_blank");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>